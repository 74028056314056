import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../components/Hero";
import { useSelector } from "react-redux";
const HomeScreen = () => {
  // React Router Navigate
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [navigate, userInfo]);

  return (
    <>
      <Hero />
    </>
  );
};

export default HomeScreen;

import React from "react";
import { useSelector } from "react-redux";
// import { useGetBlogPostsQuery } from "../slices/blogsApiSlice";
// import BlogCard from "../components/BlogCard";
// import Loader from "../components/Loader";
const DashboardScreen = () => {
  // Use the generated hook to fetch blog posts
  const { userInfo } = useSelector((state) => state.auth);

  // const { data: blogPosts, isLoading, isError } = useGetBlogPostsQuery();

  // Check loading and error states
  // if (isLoading) {
  //   return;
  //   <div className="preLoader">
  //     <Loader />
  //   </div>;
  // }

  // if (isError) {
  //   return <div>Error loading blog posts</div>;
  // }

  // Display the blog posts
  return (
    <div className="my-4">
      <h1>Hello {userInfo["username"]}</h1>
      <hr />
      <div className="mt-4">
        <h2 className="mb-4">Blog Posts</h2>
        {/* {blogPosts &&
          blogPosts.map((post) => (
            <BlogCard key={post.id} postDetails={post} />
          ))} */}
      </div>
    </div>
  );
};

export default DashboardScreen;

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import Loader from "../components/Loader";

const RegisterScreen = () => {
  const [username, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState({
    type: null,
    className: null,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  // React Router Navigate
  const navigate = useNavigate();

  // Redux Dispatch
  const dispatch = useDispatch();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const checkPasswordStrength = () => {
    // Define your password strength rules here
    const strengthRules = [
      {
        pattern: /[a-z]/,
        message: "At least one lowercase letter",
      },
      { pattern: /[A-Z]/, message: "At least one uppercase letter" },
      { pattern: /[0-9]/, message: "At least one digit" },
      {
        pattern: /[@$!%*?&]/,
        message: "At least one special character (@, $, !, %, *, ?, &)",
      },
      { pattern: /.{6,}/, message: "At least 6 characters long" },
    ];

    let currentStrength = 0;

    strengthRules.forEach((rule) => {
      if (rule.pattern.test(password)) {
        currentStrength += 1;
      }
    });

    // Determine the overall strength
    if (currentStrength === 5) {
      setStrength({
        type: "Your Password Is Strong",
        className: "checkPasswordMeter strong",
      });
    } else if (currentStrength >= 3) {
      setStrength({
        type: "Your Password Is Moderate",
        className: "checkPasswordMeter moderate",
      });
    } else if (currentStrength > 1 && currentStrength <= 3) {
      setStrength({
        type: "Your Password Is Weak",
        className: "checkPasswordMeter weak",
      });
    } else {
      setStrength({
        type: `Your Password must be ${strengthRules[4].message}`,
        className: null,
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Regular expression to allow only alphanumeric characters and underscores
    const sanitizeRegex = /^[a-zA-Z0-9_]+$/;

    if (!sanitizeRegex.test(username)) {
      toast.error(
        "Username can only contain letters, numbers, and underscores."
      );
    } else if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else if (captchaValue !== null && captchaValue !== "") {
      try {
        const res = await register({
          username,
          password,
          email,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate("/");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      toast.error("Prove that you are not a bot!");
    }
  };

  return (
    <FormContainer>
      <h1>Register</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="my-2" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="name"
            placeholder="Enter Username"
            value={username}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="my-2" controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="my-2" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              checkPasswordStrength();
            }}
          ></Form.Control>
          {strength.type && (
            <div className="d-flex flex-column py-3 passwordMeter">
              <div className={strength.className}></div>
              <div className="text-center small pt-2">{strength.type}</div>
            </div>
          )}
        </Form.Group>
        <Form.Group className="my-2" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="my-2" controlId="confirmPassword">
          <Form.Label>Are you a bot?</Form.Label>
          <ReCAPTCHA
            sitekey="6LdOiyopAAAAAB4hcUalo4QGs2YJm78wZmU1-QOx"
            onChange={handleCaptchaChange}
          />
        </Form.Group>
        {isLoading && (
          <div className="preLoader">
            <Loader />
          </div>
        )}
        <Button type="submit" variant="primary" className="mt-3">
          Register
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Already have an account? <Link to={`/login`}>Login</Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;

import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Access the pathname from the location object and set it as the body tag id
    if (location.pathname.substr(1)) {
      document.body.id = `web-${location.pathname.substr(1)}` || "home";
    } else {
      document.body.id = "web-homepage";
    }
    if (
      document.body.id === "web-homepage" ||
      document.body.id === "web-login"
    ) {
      document.querySelector(".navbar").style.display = "none";
    } else {
      document.querySelector(".navbar").style.display = "block";
    }
  }, [location.pathname]);
  return (
    <>
      <Header />
      <ToastContainer />
      <Container className="my-2">
        <Outlet />
      </Container>
    </>
  );
};

export default App;

import { Container, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
const Hero = () => {
  return (
    <div className=" py-5">
      <Container className="d-flex justify-content-center">
        <Card className="p-5 d-flex flex-column align-items-center hero-card bg-light w-75">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="184"
              height="25"
              viewBox="0 0 184 25"
              fill="none"
            >
              <path
                d="M175.505 0C176.688 0 177.76 0.175781 178.721 0.527344C179.682 0.878906 180.497 1.35938 181.165 1.96875C181.844 2.57812 182.366 3.29297 182.729 4.11328C183.092 4.92188 183.274 5.7832 183.274 6.69727V17.3848C183.274 18.2988 183.092 19.166 182.729 19.9863C182.366 20.7949 181.844 21.5039 181.165 22.1133C180.497 22.7227 179.682 23.2031 178.721 23.5547C177.76 23.9062 176.688 24.082 175.505 24.082H170.09C168.907 24.082 167.835 23.9062 166.874 23.5547C165.913 23.2031 165.092 22.7227 164.413 22.1133C163.745 21.5039 163.229 20.7949 162.866 19.9863C162.503 19.166 162.321 18.2988 162.321 17.3848V6.69727C162.321 5.7832 162.503 4.92188 162.866 4.11328C163.229 3.29297 163.745 2.57812 164.413 1.96875C165.092 1.35938 165.913 0.878906 166.874 0.527344C167.835 0.175781 168.907 0 170.09 0H175.505ZM177.807 4.39453L177.544 2.91797L176.436 1.79297L174.661 1.58203H170.846L169.089 1.79297L167.981 2.91797L167.717 4.39453V12.7441L167.981 14.2207L169.089 15.3281L170.846 15.5566H175.276L177.333 15.293L176.893 13.3945L175.241 13.6758H170.969L169.844 13.5L169.598 12.4453V8.82422H177.807V4.39453ZM169.598 6.97852V4.60547L169.739 3.60352L170.969 3.41016H174.555L175.751 3.60352L175.926 4.64062V6.97852H169.598Z"
                fill="#01CF0A"
              />
              <path
                d="M150.611 14.2559H145.215V1.58203H143.351V16.1191H150.611V14.2559ZM148.572 0C149.756 0 150.828 0.175781 151.789 0.527344C152.75 0.878906 153.564 1.35938 154.232 1.96875C154.912 2.57812 155.433 3.29297 155.797 4.11328C156.16 4.92188 156.342 5.7832 156.342 6.69727V17.3848C156.342 18.2988 156.16 19.166 155.797 19.9863C155.433 20.7949 154.912 21.5039 154.232 22.1133C153.564 22.7227 152.75 23.2031 151.789 23.5547C150.828 23.9062 149.756 24.082 148.572 24.082H143.158C141.974 24.082 140.902 23.9062 139.941 23.5547C138.98 23.2031 138.16 22.7227 137.48 22.1133C136.812 21.5039 136.297 20.7949 135.933 19.9863C135.57 19.166 135.388 18.2988 135.388 17.3848V6.69727C135.388 5.7832 135.57 4.92188 135.933 4.11328C136.297 3.29297 136.812 2.57812 137.48 1.96875C138.16 1.35938 138.98 0.878906 139.941 0.527344C140.902 0.175781 141.974 0 143.158 0H148.572Z"
                fill="#01CF0A"
              />
              <path
                d="M121.64 0C122.823 0 123.895 0.175781 124.856 0.527344C125.817 0.878906 126.632 1.35938 127.3 1.96875C127.979 2.57812 128.501 3.29297 128.864 4.11328C129.227 4.92188 129.409 5.7832 129.409 6.69727V17.3848C129.409 18.2988 129.227 19.166 128.864 19.9863C128.501 20.7949 127.979 21.5039 127.3 22.1133C126.632 22.7227 125.817 23.2031 124.856 23.5547C123.895 23.9062 122.823 24.082 121.64 24.082H116.225C115.042 24.082 113.97 23.9062 113.009 23.5547C112.048 23.2031 111.227 22.7227 110.548 22.1133C109.88 21.5039 109.364 20.7949 109.001 19.9863C108.638 19.166 108.456 18.2988 108.456 17.3848V6.69727C108.456 5.7832 108.638 4.92188 109.001 4.11328C109.364 3.29297 109.88 2.57812 110.548 1.96875C111.227 1.35938 112.048 0.878906 113.009 0.527344C113.97 0.175781 115.042 0 116.225 0H121.64ZM125.261 4.42969L125.015 2.95312L123.907 1.82812L122.097 1.58203H117.386L115.593 1.82812L114.468 2.95312L114.257 4.42969V13.2891L114.468 14.8008L115.628 15.9082L117.386 16.1191H122.097L123.907 15.9082L125.015 14.8008L125.261 13.2891V4.42969ZM123.151 14.0801L121.991 14.2559H117.491L116.349 14.0801L116.085 13.0254V4.60547L116.261 3.63867L117.491 3.44531H121.991L123.222 3.63867L123.397 4.67578V13.0957L123.151 14.0801Z"
                fill="#01CF0A"
              />
              <path
                d="M94.707 0C95.8906 0 96.9629 0.175781 97.9238 0.527344C98.8848 0.878906 99.6992 1.35938 100.367 1.96875C101.047 2.57812 101.568 3.29297 101.932 4.11328C102.295 4.92188 102.477 5.7832 102.477 6.69727V17.3848C102.477 18.2988 102.295 19.166 101.932 19.9863C101.568 20.7949 101.047 21.5039 100.367 22.1133C99.6992 22.7227 98.8848 23.2031 97.9238 23.5547C96.9629 23.9062 95.8906 24.082 94.707 24.082H89.293C88.1094 24.082 87.0371 23.9062 86.0762 23.5547C85.1152 23.2031 84.2949 22.7227 83.6152 22.1133C82.9473 21.5039 82.4316 20.7949 82.0684 19.9863C81.7051 19.166 81.5234 18.2988 81.5234 17.3848V6.69727C81.5234 5.7832 81.7051 4.92188 82.0684 4.11328C82.4316 3.29297 82.9473 2.57812 83.6152 1.96875C84.2949 1.35938 85.1152 0.878906 86.0762 0.527344C87.0371 0.175781 88.1094 0 89.293 0H94.707ZM98.0117 10.6172L97.7656 9.10547L96.6406 7.99805L94.8125 7.80469H90.875L89.8027 7.59375C89.7441 7.39453 89.7031 7.21875 89.6797 7.06641C89.6562 6.90234 89.6152 6.73242 89.5566 6.55664V4.71094L89.8027 3.67383L90.9629 3.44531H95.4277L97.0801 3.7793L97.502 1.86328L95.4629 1.58203H90.8398L89.082 1.82812L87.9395 2.95312L87.7109 4.42969V6.83789L87.9395 8.31445L89.082 9.42188L90.8398 9.65039H94.8125L95.8848 9.82617C95.9199 10.002 95.9609 10.166 96.0078 10.3184C96.0547 10.4707 96.0957 10.6289 96.1309 10.793V13.0957C96.1074 13.248 96.0664 13.4121 96.0078 13.5879C95.9609 13.7637 95.9199 13.9277 95.8848 14.0801L94.7422 14.2559H90.3828L88.6602 13.9746L88.2207 15.873L90.2773 16.1191H94.8477L96.6406 15.9082L97.7656 14.8008L98.0117 13.2891V10.6172Z"
                fill="#01CF0A"
              />
              <path
                d="M71.0441 4.39453L70.798 2.91797L69.673 1.79297L67.88 1.58203H63.4855L60.3214 2.02148V16.1191H62.1847V3.63867L63.5558 3.41016H67.7745L68.9874 3.60352L69.1632 4.64062V16.1191H71.0441V4.39453ZM67.7745 0C68.9581 0 70.0304 0.175781 70.9913 0.527344C71.9523 0.878906 72.7667 1.35938 73.4347 1.96875C74.1144 2.57812 74.6359 3.29297 74.9991 4.11328C75.3624 4.92188 75.5441 5.7832 75.5441 6.69727V17.3848C75.5441 18.2988 75.3624 19.166 74.9991 19.9863C74.6359 20.7949 74.1144 21.5039 73.4347 22.1133C72.7667 22.7227 71.9523 23.2031 70.9913 23.5547C70.0304 23.9062 68.9581 24.082 67.7745 24.082H62.3605C61.1769 24.082 60.1046 23.9062 59.1437 23.5547C58.1827 23.2031 57.3624 22.7227 56.6827 22.1133C56.0148 21.5039 55.4991 20.7949 55.1359 19.9863C54.7726 19.166 54.5909 18.2988 54.5909 17.3848V6.69727C54.5909 5.7832 54.7726 4.92188 55.1359 4.11328C55.4991 3.29297 56.0148 2.57812 56.6827 1.96875C57.3624 1.35938 58.1827 0.878906 59.1437 0.527344C60.1046 0.175781 61.1769 0 62.3605 0H67.7745Z"
                fill="#01CF0A"
              />
              <path
                d="M40.842 0C42.0256 0 43.0979 0.175781 44.0588 0.527344C45.0198 0.878906 45.8342 1.35938 46.5022 1.96875C47.1819 2.57812 47.7034 3.29297 48.0667 4.11328C48.4299 4.92188 48.6116 5.7832 48.6116 6.69727V17.3848C48.6116 18.2988 48.4299 19.166 48.0667 19.9863C47.7034 20.7949 47.1819 21.5039 46.5022 22.1133C45.8342 22.7227 45.0198 23.2031 44.0588 23.5547C43.0979 23.9062 42.0256 24.082 40.842 24.082H35.428C34.2444 24.082 33.1721 23.9062 32.2112 23.5547C31.2502 23.2031 30.4299 22.7227 29.7502 22.1133C29.0823 21.5039 28.5667 20.7949 28.2034 19.9863C27.8401 19.166 27.6584 18.2988 27.6584 17.3848V6.69727C27.6584 5.7832 27.8401 4.92188 28.2034 4.11328C28.5667 3.29297 29.0823 2.57812 29.7502 1.96875C30.4299 1.35938 31.2502 0.878906 32.2112 0.527344C33.1721 0.175781 34.2444 0 35.428 0H40.842ZM44.4631 4.42969L44.217 2.95312L43.1096 1.82812L41.2991 1.58203H36.5881L34.7952 1.82812L33.6702 2.95312L33.4592 4.42969V13.2891L33.6702 14.8008L34.8303 15.9082L36.5881 16.1191H41.2991L43.1096 15.9082L44.217 14.8008L44.4631 13.2891V4.42969ZM42.3538 14.0801L41.1936 14.2559H36.6936L35.551 14.0801L35.2874 13.0254V4.60547L35.4631 3.63867L36.6936 3.44531H41.1936L42.4241 3.63867L42.5999 4.67578V13.0957L42.3538 14.0801Z"
                fill="#01CF0A"
              />
              <path
                d="M13.681 1.58203H9.4447L7.63416 1.79297L6.52673 2.91797L6.26306 4.39453V13.3242L6.52673 14.8008L7.66931 15.9082L9.4447 16.1191H13.681L15.7377 15.873L15.2982 13.9746L13.6459 14.2559H9.55017L8.39001 14.0801L8.14392 13.0254V4.57031L8.3197 3.60352L9.55017 3.41016H13.5756L15.3861 3.70898L15.7377 1.82812L13.681 1.58203ZM13.9095 0C15.0931 0 16.1654 0.175781 17.1263 0.527344C18.0873 0.878906 18.9017 1.35938 19.5697 1.96875C20.2494 2.57812 20.7709 3.29297 21.1342 4.11328C21.4974 4.92188 21.6791 5.7832 21.6791 6.69727V17.3848C21.6791 18.2988 21.4974 19.166 21.1342 19.9863C20.7709 20.7949 20.2494 21.5039 19.5697 22.1133C18.9017 22.7227 18.0873 23.2031 17.1263 23.5547C16.1654 23.9062 15.0931 24.082 13.9095 24.082H8.49548C7.31189 24.082 6.23962 23.9062 5.27869 23.5547C4.31775 23.2031 3.49744 22.7227 2.81775 22.1133C2.14978 21.5039 1.63416 20.7949 1.27087 19.9863C0.907593 19.166 0.725952 18.2988 0.725952 17.3848V6.69727C0.725952 5.7832 0.907593 4.92188 1.27087 4.11328C1.63416 3.29297 2.14978 2.57812 2.81775 1.96875C3.49744 1.35938 4.31775 0.878906 5.27869 0.527344C6.23962 0.175781 7.31189 0 8.49548 0H13.9095Z"
                fill="#01CF0A"
              />
            </svg>
          </div>
          <p className="text-center mb-2 welcome-content">
            Hello!
            <br /> The Site is Under Construction for now.
          </p>
          <p className="text-center mb-4 welcome-content">
            We will be live soon!
          </p>
          <div className="d-flex">
            <LinkContainer to="/login">
              <Button variant="primary" className="me-3">
                Sign In
              </Button>
            </LinkContainer>
            <LinkContainer to="/register">
              <Button variant="secondary" className="me-3">
                Sign Up
              </Button>
            </LinkContainer>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default Hero;
